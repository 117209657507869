import { gql } from '@apollo/client';

export const GET_GTM_CONFIG = gql`
    query getGTMConfig {
        storeConfig {
            google_analytics4_account
            google_analytics4_active
            gtm_auth_param
            gtm_preview_param
            gtm_source_param
            id
            price_includes_tax
            sgtm_active
            should_enable_a_b_tests
            store_code
            universal_analytics_account
            universal_analytics_active
        }
    }
`;

export const getCustomerData = gql`
    query Customer {
        customer {
            id
            customer_id
            pro_account {
                classification {
                    title
                    uid
                }
                discount
                discount_applicable
            }
        }
        facebookPixelUserData {
            key
            value
        }
    }
`;

export const getFacebookFullDataMutation = gql`
    mutation facebookPixelFullUserData($input: String) {
        facebookPixelFullUserData(input: $input) {
            key
            value
        }
    }
`;

export const FACEBOOK_DATA_QUERY = gql`
    query Customer {
        facebookPixelUserData {
            key
            value
        }
    }
`;
