import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { arrayToObject } from '@vaimo/google-tag-manager/src/helpers/utils';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { FACEBOOK_DATA_QUERY } from '@vaimo/google-tag-manager/src/talons/tagManager.gql';

export const useFacebookUserData = ({ isEnabled, isSignedIn }) => {
    // lazy query to FacebookPixelUserData
    const [runQuery, { data: userData }] = useLazyQuery(FACEBOOK_DATA_QUERY, {
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (!isEnabled) return;

        //Getting Facebook user data after logging in without page reload
        if (isSignedIn && !userData) {
            runQuery();

            return;
        }

        const facebookPixelUserData = userData?.facebookPixelUserData;
        const fb_user_data = facebookPixelUserData?.length && isSignedIn ? arrayToObject(facebookPixelUserData) : null;

        if (fb_user_data) {
            actionsTagManager.faceBookUserData({ fb_user_data });
        }
    }, [isEnabled, isSignedIn, userData?.facebookPixelUserData]);
};
